export const tradingData = [
  {
    icon: "tradingItemOne",
    title: "Quick withdrawal",
    description:
      "Our instant withdrawal facility offers fastest payout, withdraw your winning.",
  },
  {
    icon: "tradingItemTwo",
    title: "Live Fantasy play",
    description:
      "Track your individual players' performance alongside live cricket matches.",
  },
  {
    icon: "tradingItemThree",
    title: "Budget-Friendly Contest",
    description:
      "Play according to your budget, there are a series of contests starting from just Rs. 1",
  },
  {
    icon: "tradingItemFour",
    title: "Refer & Earn",
    description:
      "You can earn Rs.100 by inviting others to play using your unique invite code.",
  },
  {
    icon: "tradingItemFive",
    title: "Secure",
    description:
      "Abiding by the laws, playing on Frolicfuns is 100% legal and secure.",
  },
  {
    icon: "tradingItemSix",
    title: "24x7 customer support",
    description:
      "Need any kind of help and support just ping our support team anytime.",
  },
];

export const starPlayData = [
  {
    icon: "startPlay1",
    title: "1. Join for Free.",
    description: "Create an account in just a few minutes.",
  },
  {
    icon: "startPlay2",
    title: "2. Create Team",
    description: "Create your own team by selecting different players .",
  },
  {
    icon: "startPlay3",
    title: "3. Done",
    description: " contests where you can win real money.",
  },
];

export const accordionFullData = [
  {
    id: "flush-headingOne",
    title: "What is FrolicFuns?",
    content:
      "FrolicFuns is a fantasy sports app where users can create and join fantasy leagues, draft players, and compete against others based on real-world sports performance..",
  },
  {
    id: "flush-headingTwo",
    title: "How do I get started with FrolicFuns?",
    content:
      "Download the FrolicFuns app from the Download App Button and create an account, then join or create a league to start playing.",
  },
  {
    id: "flush-headingThree",
    title: "How do I create an account?",
    content: `Click on the "Sign Up" button on the homepage of the app, enter your details, and follow the prompts to create your account.`,
  },
  {
    id: "flush-headingFour",
    title: "I forgot my password. How can I reset it?",
    content: `Click on the "Forgot Password" link on the login page and follow the instructions to reset your password.`,
  },
  {
    id: "flush-headingFive",
    title: "How do I join a league?",
    content: `After creating an account, you can search for public leagues or enter a league code if you have one to join a private league.
`,
  },
  {
    id: "flush-headingSix",
    title: "Can I create my own league?",
    content: `Yes, you can create your own league by clicking on the "Create League" button and following the setup instructions.`,
  },
  {
    id: "flush-headingSeven",
    title: "How many teams can I have in a league?",
    content: `The number of teams per league can vary depending on the league settings. Typically, leagues can have between 8 to 16 teams.`,
  },
  {
    id: "flush-headingEight",
    title: "How does the draft work?",
    content: `The draft can be conducted in different formats such as live online, offline, or auto-pick. You can choose your preferred method during league setup.`,
  },
];
