import React, { useEffect, useState } from "react";
import { Icon } from "../../ui";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import dwdApk from "../../apk/frolicfuns.apk";

const Header = () => {
  const [activeSection, setActiveSection] = useState(" ");
  const [isActive, setIsActive] = useState(false);
  const [scrolled, setScrolled] = useState(window.scrollY);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const scrollOnTop = () => {
    if (window.scrollY === 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollOnTop);
    return () => {
      window.removeEventListener("scroll", scrollOnTop);
    };
  }, []);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };
  const handleLinkClick = (link) => {
    navigate("/");
    setTimeout(() => {
      setActiveSection(link);
      scrollToSection(link);
    }, 0);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToSection = (div) => {
    const section = document.getElementById(div);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  // FOR ANIMATION
  useEffect(() => {
    setActiveSection("home");
    const sectionsForanimation = [
      {
        id: "whyfolicfuns",
        class: "futuresec_main",
        animationClass: "futureanimation",
      },
      {
        id: "Options",
        class: "supertrade_main",
        animationClass: "supertradanimation",
      },
      {
        id: "maxprofit",
        class: "maximum_profirsec",
        animationClass: "maximumanimation",
      },
    ];

    const handleScroll = () => {
      if (isActive) {
        setIsActive(false);
      }
      const scrollPosition = window.scrollY;
      const sections = document.querySelectorAll("section");

      sectionsForanimation.forEach((section) => {
        const sectionElement = document.getElementById(section.id);
        const element = document.querySelector("." + section.class);
        const rect = sectionElement?.getBoundingClientRect();
        if (rect?.top <= 450) {
          element.classList.add(section.animationClass);
          setActiveSection(sectionElement);
        } else if (rect?.top <= 0) {
          element.classList.remove(section.animationClass);
        }
      });
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;

        if (
          scrollPosition >= sectionTop - 180 &&
          scrollPosition < sectionTop + sectionHeight - 50
        ) {
          setActiveSection(section.id);
        }
      });
    };

    // Add animation class on initial load
    handleScroll();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {" "}
      <header className="fixed-top ">
        <div
          className={`inner-header ${
            scrolled && location.pathname === "/"
              ? "bg-transparent"
              : "bg-white"
          }`}
        >
          <div className="container">
            <div className="main-header">
              <div className="logo">
                <a
                  className="navbar-brand"
                  onClick={() => {
                    toggleMenu();
                    handleLinkClick("home");
                  }}
                >
                  <Icon
                    name={`${
                      scrolled && location.pathname === "/"
                        ? "Logo"
                        : "LogoSecond"
                    }`}
                    className=""
                  />
                </a>
              </div>
              <div className="bars" id="open" onClick={toggleMenu}>
                <div className="blur">
                  <div className={`hamburger ${isActive ? "active" : ""}`}>
                    <div className="line"></div>
                    <div className="line"></div>
                    <div className="line"></div>
                  </div>
                </div>
              </div>
              <nav
                className={`navbar-items ${isActive ? "active" : ""} `}
                id="show"
              >
                <ul className={`${windowWidth <= 991 ? "container" : ""}`}>
                  <li
                    className={`${activeSection === "home" ? "activenav" : ""}`}
                  >
                    <a
                      className={`nav-link ${
                        scrolled && location.pathname === "/"
                          ? "text-white"
                          : ""
                      }`}
                      aria-current="page"
                      onClick={() => {
                        toggleMenu();
                        handleLinkClick("home");
                      }}
                    >
                      Home
                    </a>
                  </li>
                  <li
                    className={`${
                      activeSection === "Start" ? "activenav" : ""
                    }`}
                  >
                    <a
                      className={`nav-link ${
                        scrolled && location.pathname === "/"
                          ? "text-white"
                          : ""
                      }`}
                      onClick={() => {
                        toggleMenu();
                        handleLinkClick("Start");
                      }}
                    >
                      How to Play
                    </a>
                  </li>
                  <li
                    className={`${
                      activeSection === "whyfolicfuns" ? "activenav" : ""
                    }`}
                  >
                    <a
                      className={`nav-link ${
                        scrolled && location.pathname === "/"
                          ? "text-white"
                          : ""
                      }`}
                      onClick={() => {
                        toggleMenu();
                        handleLinkClick("whyfolicfuns");
                      }}
                    >
                      Why FrolicFuns ?
                    </a>
                  </li>
                  <li
                    className={`${
                      activeSection === "aboutus" ? "activenav" : ""
                    }`}
                  >
                    <a
                      className={`nav-link ${
                        scrolled && location.pathname === "/"
                          ? "text-white"
                          : ""
                      }`}
                      onClick={() => {
                        toggleMenu();
                        handleLinkClick("aboutus");
                      }}
                    >
                      About us
                    </a>
                  </li>
                  {windowWidth <= 991 ? (
                    <div className="mt-1">
                      <a
                        type="button"
                        className="primarybtn"
                        // onClick={handleModalOpen}
                        href={dwdApk}
                        download="frolicfuns.apk"
                      >
                        Download App
                      </a>
                    </div>
                  ) : (
                    ""
                  )}
                </ul>
              </nav>

              {windowWidth > 991 ? (
                <form className="mybtn">
                  <a
                    type="button"
                    className="primarybtn"
                    // onClick={handleModalOpen}
                    href={dwdApk}
                    download="frolicfuns.apk"
                  >
                    Download App
                  </a>
                </form>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
