import React from "react";
import { returnRefund } from "../../utils/mock";

const RefundReturn = () => {

  return (
    <>
      <div className="container">
        <div className="policy_text">
          <div className="row justify-content-center my-2">
            <div className="col-lg-6 col-md-10 text-center">
              <div className="commantext">
                <h4>Refund & Cancellation Policy</h4>
              </div>
            </div>
          </div>
          <div>
            {returnRefund.map((section, index) => (
              <div className="commanOther" key={index}>
                <h4>{section.title}</h4>
                <p style={{ whiteSpace: "pre-wrap" }}>{section.content}</p>

              </div>
            ))}
            
         {/*  <div className="commanOther">
            <h4>8. Contact Us</h4>
            <p>
              If you have any questions or concerns about this Privacy Policy,
              please contact us at:{" "}
              <a href="mailto:support@frolicfuns.com">support@frolicfuns.com</a>
            </p>
          </div> */}
          </div>
          {/* <p>
            <b>Thank you for using FrolicFuns!</b>
          </p> */}
        </div>
      </div>
    </>
  );
};

export default RefundReturn;