import React, { useEffect, useRef, useState } from "react";
import Button from "../../ui/elements/Button";
import { Icon } from "../../ui";
import { accordionFullData, starPlayData, tradingData } from "./mock";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/swiper-bundle.css";
import "swiper/css/pagination";
import { NavLink } from "react-router-dom";
import dwdApk from "../../apk/frolicfuns.apk";

const Home = () => {
  const [activeSection, setActiveSection] = useState(" ");
  const [isActive, setIsActive] = useState(false);
  const [scrolled, setScrolled] = useState(window.scrollY);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const scrollOnTop = () => {
    if (window.scrollY === 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollOnTop);
    return () => {
      window.removeEventListener("scroll", scrollOnTop);
    };
  }, []);

  const toggleMenu = () => {
    setIsActive(!isActive);
  };
  const handleLinkClick = (link) => {
    setActiveSection(link);
    scrollToSection(link);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToSection = (div) => {
    const section = document.getElementById(div);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  // FOR ANIMATION
  useEffect(() => {
    setActiveSection("home");
    const sectionsForanimation = [
      {
        id: "whyfolicfuns",
        class: "futuresec_main",
        animationClass: "futureanimation",
      },
      {
        id: "Options",
        class: "supertrade_main",
        animationClass: "supertradanimation",
      },
      {
        id: "maxprofit",
        class: "maximum_profirsec",
        animationClass: "maximumanimation",
      },
    ];

    const handleScroll = () => {
      if (isActive) {
        setIsActive(false);
      }
      const scrollPosition = window.scrollY;
      const sections = document.querySelectorAll("section");

      sectionsForanimation.forEach((section) => {
        const sectionElement = document.getElementById(section.id);
        const element = document.querySelector("." + section.class);
        const rect = sectionElement?.getBoundingClientRect();
        if (rect?.top <= 450) {
          element.classList.add(section.animationClass);
          setActiveSection(sectionElement);
        } else if (rect?.top <= 0) {
          element.classList.remove(section.animationClass);
        }
      });
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;

        if (
          scrollPosition >= sectionTop - 180 &&
          scrollPosition < sectionTop + sectionHeight - 50
        ) {
          setActiveSection(section.id);
        }
      });
    };

    // Add animation class on initial load
    handleScroll();

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <section id="home">
        <div className="lightbg position-relative homebg">
          <div className="container">
            <div className="row justify-content-betweeen align-items-center herosec_main">
              <div className="col-lg-4 my-2">
                <div className="heroanimation">
                  <div className="hero-sec">
                    <h1>
                      Trust, Build and <span> Win</span>
                    </h1>
                    <p>
                      Trust your cricket knowledge, Create team, Win real money
                      and Withdraw easily in your account
                    </p>
                    <div className="twobutton">
                      <div>
                        <a
                          className="primarybtn pointer"
                          // onClick={() => {
                          //   toggleMenu();
                          //   handleLinkClick("contact");
                          // }}
                          href={dwdApk}
                          download="frolicfuns.apk"
                        >
                          Download Now
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-2">
                <div className="heroimg ">
                  <Icon name="Heroimg" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="usermain">
          <div className="container">
            <div className="user_detail">
              <div className="user_numbersub">
                <div>
                  <Icon name="usersNumber" className="img-fluid" />
                </div>
                <div>
                  <h5>10,000+</h5>
                  <p>Users</p>
                </div>
              </div>
              <div className="user_numbersub">
                <div>
                  <Icon name="winningsNumber" className="img-fluid" />
                </div>
                <div>
                  <h5>₹1 Crores+</h5>
                  <p>Winnings Distributed</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="Start">
        <div className="container">
          <div className="overflow-hidden startmain">
            <div className="row justify-content-center my-2">
              <div className="col-lg-6 col-md-10 text-center">
                <div className="commantext">
                  <h4>
                    Start Playing on <span>FrolicFuns</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="swipermain">
              <Swiper
                centeredSlides={false}
                // centeredSlides={true}
                spaceBetween={15}
                speed={900}
                initialSlide={3}
                parallax
                rewind
                loop
                effect="slide"
                freeMode
                grabCursor={true}
                watchSlidesProgress
                slidesPerView={3}
                breakpoints={{
                  1200: { slidesPerView: 2 },
                  992: { slidesPerView: 1.5 },
                  576: { slidesPerView: 1.1 },
                  425: { slidesPerView: 1.2 },
                  0: { slidesPerView: 1.2 },
                }}
                autoplay={{
                  delay: 19000,
                }}
                // pagination={{ clickable: true }}
                // modules={[Autoplay, Pagination]}
                pagination={{
                  clickable: true,
                  el: ".swiper-pagination", // Target the pagination element
                }}
                modules={[Pagination]}
              >
                {starPlayData?.map((data, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <div className="play_card">
                        <div className="play_cardtext">
                          <h4>{data?.title}</h4>
                          <p>{data?.description}</p>
                          {/* <Button className="primarybtn">Learn More</Button> */}
                        </div>
                        <div className="playcard_image">
                          <Icon name={data.icon} className="img-fluid" />
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              <div className="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </section>

      <div className="container">
        <section id="whyfolicfuns">
          <div className="futuresec_main pt-0">
            <div className="row justify-content-center my-2">
              <div className="col-lg-6 col-md-10 text-center">
                <div className="commantext">
                  <h4>Why FrolicFuns?</h4>
                </div>
              </div>
            </div>
            <div className="tradingsec">
              {tradingData.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="trading_sub"
                    // style={{ animationDelay: `${index + 0.1}s` }}
                  >
                    <div className="tradingIcon">
                      <Icon name={item?.icon} className="img-fluid" />
                    </div>
                    <div>
                      <h5>{item?.title}</h5>
                      <p>{item?.description}</p>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </div>

      <div className="earnmain">
        <div className="container">
          <div className="row justify-content-center my-2">
            <div className="col-lg-6 col-md-10 text-center">
              <div className="commantext">
                <h4>
                  Earn more with <span>FrolicFuns</span>
                </h4>
              </div>
            </div>
          </div>
          <div className="earn_card_main">
            <div className="earn_card">
              <Icon name="earn1" className="img-fluid" />
              <h5>Refer Friends</h5>
              <p>
                Invite friend & family to get instant bonus + 10 level
                commission income
              </p>
              <div className="knowbtn">
                <Button
                  onClick={() => {
                    toggleMenu();
                    handleLinkClick("footer");
                  }}
                  className="primarybtn"
                >
                  Know More
                </Button>
              </div>
            </div>
            <div className="earn_card">
              <Icon name="earn2" className="img-fluid" />
              <h5>Daily Bonus</h5>
              <p>
                Earn rewards every day with our exclusive daily bonus program
              </p>
              <div className="knowbtn">
                <Button
                  className="primarybtn"
                  onClick={() => {
                    toggleMenu();
                    handleLinkClick("footer");
                  }}
                >
                  Know More
                </Button>
              </div>
            </div>
            <div className="earn_card">
              <Icon name="earn3" className="img-fluid" />
              <h5>Offers</h5>
              <p>
                Unlock amazing deals with our special offers, available for a
                limited time only!
              </p>
              <div className="knowbtn">
                <Button
                  className="primarybtn"
                  onClick={() => {
                    toggleMenu();
                    handleLinkClick("footer");
                  }}
                >
                  Know More
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <section id="aboutus">
          <div className="supertrade_main">
            <div>
              <div className="row align-items-center maximum_profirsec">
                <div className="col-lg-5 ">
                  <div className="mxprofit-hero">
                    <Icon name="maxProfit" className="img-fluid" />
                  </div>
                </div>
                <div className="col-lg-7 ">
                  <div className="commanOther">
                    <h4>What we are ?</h4>
                    <p>
                      Owned and operated by VARINA IT SERVICES PRIVATE LIMITED.,
                      Frolicfuns.com is an online fantasy game designed for the
                      fans who like to showcase their cricket knowledge and
                      analytical skills.
                    </p>
                    <p>
                      Here, you don a selector’s hat and create a playing XI of
                      your choice – a set of players you think will keep the
                      scorers busy and spectators on the edge of their seats.
                      Here, you trust your skills and create your own fortune –
                      all with the help of on-field players you placed your
                      trust in.
                    </p>
                    <p>
                      You aren’t a mere spectator anymore. You get to be the
                      manager of your team.
                    </p>
                    <p>
                      Frolicfuns.com is managed by a professional management
                      team with several years of experience in the online games
                      industry. To find out more about the job opportunities,
                      work culture and the life at VARINA IT SERVICES PRIVATE
                      LIMITED
                    </p>
                    <NavLink to={"/about-us"} className="primarybtn">
                      Know more
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Crafting sec */}

        <section id="learn"></section>
      </div>
      <div className="container">
        <div className="accordsec">
          <div className="container">
            <div className="row justify-content-center my-2">
              <div className="col-lg-6 col-md-10 text-center">
                <div className="commantext">
                  <h4>
                    FAQ’s About <span> Frolicfuns</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-5">
                <div
                  className="accordion accordion-flush selfaccord"
                  id="accordionFlushExample"
                >
                  {accordionFullData?.slice(0, 4)?.map((item, index) => (
                    <div className="accordion-item" key={index}>
                      <h2 className="accordion-header" id={item.id}>
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#flush-collapse${index}1`}
                          aria-expanded="false"
                          aria-controls={`flush-collapse${index}1`}
                        >
                          {item.title}
                        </button>
                      </h2>
                      <div
                        id={`flush-collapse${index}1`}
                        className="accordion-collapse collapse"
                        aria-labelledby={item.id}
                        data-bs-parent="#accordionFlushExample"
                      >
                        <div className="accordion-body">{item.content}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className="col-lg-5">
                <div
                  className="accordion accordion-flush selfaccord"
                  id="accordionFlushExample2"
                >
                  {accordionFullData?.slice(4, 8)?.map((item, index) => (
                    <div className="accordion-item" key={index}>
                      <h2 className="accordion-header" id={item.id}>
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#flush-collapse${index}2`}
                          aria-expanded="false"
                          aria-controls={`flush-collapse${index}2`}
                        >
                          {item.title}
                        </button>
                      </h2>
                      <div
                        id={`flush-collapse${index}2`}
                        className="accordion-collapse collapse"
                        aria-labelledby={item.id}
                        data-bs-parent="#accordionFlushExample2"
                      >
                        <div className="accordion-body">{item.content}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* /Modal */}
      {/* <ContactModal show={isModalOpen} onClose={handleModalClose} /> */}
      {/* /Modal */}
    </>
  );
};

export default Home;
